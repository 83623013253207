import useTicker from '../../hooks/useTicker';
import tools from '../../utils/tools';

const ProductModel = ({ product, model }) => {
  const { calcPrice, ticker } = useTicker();
  return (
    <a className="categoryCard" href={`/product/${product.slug}`}>
      {(product.count > 0 || product.status == 0) ? (product.status == 0 && product.countForeign > 0 ? '' : <span className={`catCardTag catCardStatus${product.count > 0 && product.status == 1 || product.countForeign > 0 ? '' : ' unavailable'}`}>{product.count > 0 && product.status == 1 ? product.count  +  ' - موجود' : 'ناموجود'}</span>) : ''}
      {product.countForeign > 0 && <span className="catCardTag catCardStatus foreign">{product.countForeign + ' - پیش سفارش'}</span>}
      {product.discountPrice > 0 && <span className="catCardTag">-{product.price - product.discountPrice} DHs</span>}
      <div className="productCardImgContainer">
      <img src={tools.getMainPic(product?.medias)?.url} className="catCardardImg" onMouseEnter={(e: any) => {
        if (product?.medias.length > 1) {
          e.target.src = product?.medias[1].url;
        }
      }} onMouseLeave={(e: any) => e.target.src = tools.getMainPic(product?.medias)?.url}/>
      </div>
      <div className="catCardInfo">
        <h6 className="catCardName">{model.title}</h6>
        {/* <span className="catCardSku">{product.sku}</span> */}
        <div className="priceSec model">
          {model.status == 1 ?
            <>
              <h6 className={`catCardPrice`}>{`${calcPrice(model.priceFrom) || ''} ~ ${calcPrice(model.priceTo) || ''}`} DHs</h6>
              {/* <span>~</span> */}
              {/* <span className="catPersianPrice">
                <h6 className="tablePriceNumber">{tools.formatPrice(ticker(Number(calcPrice(model.discountPrice || product.price, false))))} </h6>
                <h6 className="tablePriceTexe">تومان</h6>
              </span> */}
            </>
            :
            <>
              <h6 className="catCardPrice">ناموجود</h6>
            </>
          }


        </div>
      </div>
    </a>
  );
};

export default ProductModel;
