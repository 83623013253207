export const GuaranteeCard = ({ data }) => {
  return (
    <div className='guaranteeFactorBox page-break' key={data?.id}>
      <div className='guaranteeFactorHeader'>
          <span>
            {data?.brandTitle}
          </span>
        <span>
            DBF
          </span>
      </div>
      <h2>Warranty Certificate</h2>
      <h3>{data?.companyName}</h3>
      <div className='guaranteeFactorCard'>
        <span>Model No:</span>
        <span>{data?.brandTitle + ' ' + data?.sku}</span>
      </div>
      <div className='guaranteeFactorCard'>
        <span>Serial No:</span>
        <span>{data?.serial}</span>
      </div>
      <p>
        با تشکر از خرید شما. لطفا جهت فعالسازی خدمات پس از فروش کالای خود و اطمینان از اصالت محصول،‌کد بالا را در وبسایت زیر ثبت نمایید
      </p>
      <p>guarantee.dbf.ir</p>
    </div>
  );
};