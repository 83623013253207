import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { popupSlice } from "../../../services/reducers";
import { useDispatch } from "react-redux";
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import tools from '../../../utils/tools';

const OrderDetail = ({ order, onGetPDF }: any) =>{

  const list = () => {
    return order.products?.sort((a, b) => b.brandId - a.brandId)?.map((orderProduct, index) =>
      <tr key={orderProduct.id}>
        {/* <td> */}
          {/* <i className='factorSvg' onClick={async () => await guaranteeFactorGenerator(orderProduct.id)}></i> */}
        {/* </td> */}
        <td className={orderProduct.discountPrice > 0 ? 'discounted' : ''}>{tools.formatPrice(orderProduct.discountPrice || orderProduct.price)}</td>
        <td>{orderProduct.countWarehouse + ` - (${orderProduct.countForeign})`}</td>
        <td>{orderProduct.product.sku}</td>
        <td><a href={'/product/' + orderProduct.product.slug} target="_blank">{orderProduct.product.title}</a></td>
        <td><img className="maxWidth100" src={orderProduct.product?.medias[0]?.url} alt={orderProduct.product.title}/></td>
        <td>{++index}</td>
      </tr>
    )
  }


  return(
    <main className="billDetailContainer">
      <table>
        <thead>
        <tr>
          {/* <th>عملیات</th> */}
          <th>قیمت</th>
          <th>تعداد (خارج)</th>
          <th>sku</th>
          <th>محصول</th>
          <th>عکس</th>
          <th>ردیف</th>
        </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
    </main>
  )
}
export default OrderDetail