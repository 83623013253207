import moment from 'jalali-moment';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import alertHandler from '../../../utils/alertHandler';
import tools from '../../../utils/tools';
import { Sidebar } from '../Layout/Sidebar';

const OrderManage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  // const productReducer = useAppSelector(state => state.productReducer);
  const [statuses, setStatuses] = useState([]);
  const [form, setForm] = useState<any>({
    orderProducts: [],
    finalPrice: 0,
    discountAmount: 0,
  });
  const [products, setProducts] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();
  const send = async () => {
    dispatch(setLoading(true));
    let addressRes, userRes, verifyNationalCode;
    if (!id) {
      // if (!form.user?.isVerified) {
      //   verifyNationalCode = await restApi(process.env.REACT_APP_BASE_URL + '/admin/user/verify').post({
      //     phoneNumber: form.user.phoneNumber,
      //     nationalCode: form.user.nationalCode
      //   });
      //   if (verifyNationalCode.code == 1005 && !confirm('کد ملی با شماره تلفن تطابق ندارد آیا به هرحال سفارش ثبت شود؟')){
      //     dispatch(setLoading(false));
      //     return;
      //   }
      // }

      userRes = await restApi(process.env.REACT_APP_BASE_URL + '/admin/user/basic/' + (form?.user?.id || ''), true).post({
        name: form.user.name,
        lastName: form.user.lastName,
        nationalCode: form.user.nationalCode,
        phoneNumber: form.user.phoneNumber,
        isVerified: verifyNationalCode?.code == 200 || form.user.isVerified
      });

      addressRes = await restApi(process.env.REACT_APP_BASE_URL + '/admin/address/basic/' + (form?.address?.id || ''), true).post({
        title: form.address.title,
        phoneNumber: form.address.phoneNumber,
        text: form.address.text,
        userId: userRes.data?.id,
        provinceId: form.address.provinceId,
        cityId: form.address.cityId,
      });
    }

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/basic/' + (id || ''), true).post({
      date: form.date,
      time: form.time,
      status: form.status,
      finalPrice: form.finalPrice,
      price: form.price,
      productId: form.productId,
      discountAmount: form.discountAmount,
      transportation: form.transportation,
      addressId: form.address?.id || addressRes?.data?.id,
      userId: form.user?.id || userRes.data?.id,
      isMulti: form.isMulti,
      isUrgent: form.isUrgent
    });

    const ordProds = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/products/' + res.data.id, true).put({
      products: form.orderProducts.map(e => ({
        productId: e.productId,
        count: e.count
      }))
    });
    alertHandler([ordProds, res], `سفارش با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد`, () => navigate(-1))

    dispatch(setLoading(false));
  };

  const list = () => {
    const rows: ReactElement[] = [];
    form?.orderProducts?.map((orderProduct: any, index) => {
      const key = form.orderProducts?.findIndex(e => e.productId == orderProduct.productId);
      rows.push(
        <tr className="" key={'product' + index}>
          <td className="backGround1">
            <p>{tools.formatPrice(products?.find(e => e.id == orderProduct.productId)?.price * orderProduct.count)}</p>
          </td>
          <td>
            <p>{tools.formatPrice(products?.find(e => e.id == orderProduct.productId)?.price)}</p>
          </td>
          <td className="quantity">
            <div className="quantityButtom">
              <i className="tablePlusIcon" onClick={(e: any) => {
                setForm(prev => {
                  const cp = { ...prev };

                  cp.orderProducts[key].count = Number(cp.orderProducts[key].count) + 1;

                  return cp;
                });
              }}></i>
              <input className="quantityNumber" value={orderProduct.count}
                     onChange={(input) => setForm(prev => {
                       const cp = { ...prev };

                       cp.orderProducts[key].count = Number(input.target.value);

                       return cp;
                     })}
              />
              <i className="tableCollapsIcon" onClick={(e: any) => {
                setForm(prev => {
                  const cp = { ...prev };

                  if (cp.orderProducts[key].count > 1) {
                    cp.orderProducts[key].count = Number(cp.orderProducts[key].count) - 1;
                  }
                  return cp;
                });
              }}></i>
            </div>
          </td>
          <td className="">
            <Select
              options={products.filter(e => e.price > 0).map(e => ({
                value: e.id,
                label: e.sku + ' - ' + e.title
              }))}
              value={{
                value: orderProduct.productId,
                label: products.find(e => e.id == orderProduct.productId)?.sku
              }}
              onChange={(selected) => {
                setForm(prev => ({
                  ...prev,
                  orderProducts: (key == undefined || key < 0) ? [...prev, { productId: selected.value }] : prev.orderProducts.map(e => e.productId == orderProduct.productId ? {
                    ...e,
                    productId: selected.value
                  } : e)
                }));
              }}
            />
          </td>
          {/* <td><img className="width100p" src={orderProduct.product.medias.find(e => e.code == 'main')?.url}/></td> */}
          <td>{++index}</td>
          <td>
            <i className="cancelSvg" onClick={() => setForm(prev => ({
              ...prev,
              orderProducts: prev.orderProducts.filter(e => e.productId != orderProduct.productId)
            }))}></i>
          </td>
        </tr>
      );
    });

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    if (id) {
      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/single/' + id, true).get();

      setForm({
        status: res.data?.status,
        finalPrice: Number(res.data?.finalPrice),
        price: res.data?.price,
        discountAmount: res.data?.discountAmount,
        orderProducts: res.data?.products,
        address: res.data?.user?.address,
        user: res.data?.user,
        createdAt: res.data?.createdAt,
      });
    }
    const res = await Promise.all([
      restApi(process.env.REACT_APP_BASE_URL + '/admin/product/', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/order/status', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/address/states').get(),
    ]);

    setProducts(res[0].data);
    setStatuses(res[1].data);
    setProvinces(res[2].data);

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchUser = async (phoneNumber) => {
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/user/findBy/', true).get({ phoneNumber: phoneNumber });
    if (res.code == 200) {
      setForm(prev => ({
        ...prev,
        user: res.data,
        address: res.data.address
      }));
    }
  };

  useEffect(() => {
    if (form?.orderProducts.length > 0 && products.length > 0) {
      const newPrice = form.orderProducts?.reduce((acc, curr) => acc + Number(products?.find(e => e.id == curr.productId)?.price * Number(curr.count)), 0);
      setForm(prev => ({
        ...prev,
        price: newPrice,
        finalPrice: newPrice - Number(form?.discountPrice || 0)
      }));
    }
  }, [...JSON.stringify(form?.orderProducts), form?.discountPrice]);

  useEffect(() => {
    if (provinces.length > 0) {
      setCities((form?.address?.provinceId ? provinces?.find(e => e.id == form.address.provinceId) : provinces[0])?.cities);
    }
  }, [form?.address?.provinceId, provinces]);

  return (
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <button className="dashboardHeader keepRight" onClick={send}>
            <p>ثبت سفارش</p>
          </button>
          <span>
            <h1 className="sideBarTitle"> بازگشت به صفحه لیست سفارش ها</h1>
             <h1 className="dashBoardTitle">ویرایش سفارش</h1>
          </span>
          <i className="backAdd" onClick={() => navigate('/order')}></i>
        </div>
        <section className="topRow">
          <div className="infoSection">
            <h1 className="dashBoardTitle">اطلاعات کاربر</h1>
            <div className="userInfoContainer">
              <label className="sideBarTitle">شماره تلفن</label>
              <input className="editProductInput" value={form?.user?.phoneNumber} onChange={async (input) => {
                setForm(prev => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    phoneNumber: input.target.value
                  }
                }));
                if (input.target.value.length == 11) {
                  await fetchUser(input.target.value);
                }
              }}/>
              <label className="sideBarTitle">نام</label>
              <input className="editProductInput" value={form?.user?.name} onChange={(input) => {
                setForm(prev => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    name: input.target.value
                  }
                }));
              }}/>
              <label className="sideBarTitle">نام و خانوادگی</label>
              <input className="editProductInput" value={form?.user?.lastName} onChange={(input) => {
                setForm(prev => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    lastName: input.target.value
                  }
                }));
              }}/>
              {/* <label className="sideBarTitle" >کد ملی</label> */}
              {/* <input className="editProductInput" value={form?.user?.nationalCode} onChange={(input) => { */}
              {/*   setForm(prev => ({ */}
              {/*     ...prev, */}
              {/*     user: { */}
              {/*       ...prev.user, */}
              {/*       nationalCode: input.target.value */}
              {/*     } */}
              {/*   })) */}
              {/* }}/> */}
            </div>
          </div>
          <div className="infoSection">
            <h1 className="dashBoardTitle">اطلاعات سفارش</h1>
            <div className="userInfoContainer">
              <label className="sideBarTitle">وضغیت سفارش</label>
              <Select name="status" value={{
                value: statuses?.find(e => e.id == form?.status)?.id || 3,
                label: statuses?.find(e => e.id == form?.status)?.title || statuses.find(e => e.id == 3)?.title
              }} options={statuses?.map((e) => ({
                value: e.id,
                label: e.title
              }))} className="dashCardLog" id="infoTitle" onChange={(selected) => setForm(prev => ({
                ...prev,
                status: selected.value
              }))}/>
              <label className="sideBarTitle">تاریخ</label>
              <input className="editProductInput" value={(form?.date || moment().add(2, 'd').format('jYYYY/jMM/jDD'))} onChange={(input) => setForm(prev => ({
                ...prev,
                date: input.target.value
              }))}/>
              {/* <label className="sideBarTitle" >ساعت</label> */}
              {/* <input className="editProductInput" value={form?.time} onChange={(input) => setForm(prev => ({ ...prev, time: input.target.value}))}/> */}
              {/* <div className='inputRow'> */}
              {/*   <Switch */}
              {/*     checked={form.isUrgent} */}
              {/*     onChange={checked => setForm(prev => ({ ...prev, isUrgent: checked}))} */}
              {/*   /> */}
              {/*   <label className="sideBarTitle" >سفارش فوری</label> */}
              {/* </div> */}
            </div>
          </div>

          {/* <section className="bottom"> */}
          {/*   <h6 className="dashBoardTitle">وضعیت ها</h6> */}
          {/*   <table className="productTable"> */}
          {/*     <thead className="editOrderTable"> */}
          {/*     <th className="sideBarTitle center" >توضیحات</th> */}
          {/*     <th className="sideBarTitle center" >تاریخ</th> */}
          {/*     <th className="sideBarTitle center" >وضعیت</th> */}
          {/*     </thead> */}
          {/*     <tbody> */}
          {/*     {statusList()} */}
          {/*     </tbody> */}
          {/*     /!* <tr className="addProductTr"> *!/ */}
          {/*     /!*   <td className="addProductButton">اضافه کردن محصول</td> *!/ */}
          {/*     /!* </tr> *!/ */}
          {/*   </table> */}
          {/* </section> */}
        </section>
        <section className="topRow">
          <div className="infoSection">
            <h1 className="dashBoardTitle">آدرس</h1>
            <div className="userInfoContainer">
              <label className="sideBarTitle" htmlFor="phoneNumber">عنوان</label>
              <input className="editProductInput" type="text" id="phoneNumber" name="addressPhone" value={form.address?.title} onChange={(input: any) => setForm(prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  title: input.target.value
                }
              }))}/>
              <label className="sideBarTitle" htmlFor="phoneNumber">استان</label>
              <select className="editProductInput" id="phoneNumber" name="addressPhone" value={form.address?.provinceId} onChange={(input: any) => setForm(prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  provinceId: input.target.value
                }
              }))}>
                {provinces.map(province => <option key={province.id} value={province.id}>{province.title}</option>)}
              </select>
              <label className="sideBarTitle" htmlFor="phoneNumber">شهرستان</label>
              <select className="editProductInput" id="phoneNumber" name="addressPhone" value={form.address?.cityId} onChange={(input: any) => setForm(prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  cityId: input.target.value
                }
              }))}>
                {cities.map(city => <option key={city.id} value={city.id}>{city.title}</option>)}
              </select>
              <label className="sideBarTitle" htmlFor="phoneNumber">شماره تماس</label>
              <input className="editProductInput" type="text" id="phoneNumber" name="addressPhone" value={form.address?.phoneNumber} onChange={(input: any) => setForm(prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  phoneNumber: input.target.value
                }
              }))}/>
              <label className="sideBarTitle" htmlFor="address">جزئیات آدرس</label>
              <textarea className="editProductInput" id="address" name="addressText" value={form.address?.text} onChange={(input: any) => setForm(prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  text: input.target.value
                }
              }))}/>
            </div>
          </div>
          <div className="infoSection">
            <h1 className="dashBoardTitle">مجموع فاکتور</h1>
            <div className="userInfoContainer">
             <span className="factorHeader">
               <p>{moment(form?.createdAt).format('jYYYY/jMM/jDD')}</p>
             </span>
              {/*   <span className="billItems dashboardBill"> */}
              {/*   <h3 className="billItem">هزینه ارسال</h3> */}
              {/*   <div className="pricePart"> */}
              {/*     <input className="billPrice" value={form?.transportation} onChange={(input) => setForm(prev => ({ ...prev, transportation: Number(input.target.value) > 0 ? Number(input.target.value) : 0}))}/> */}
              {/*   </div> */}
              {/* </span> */}
              <span className="billItems dashboardBill">
              <h3 className="billItem">مبلغ سفارش</h3>
              <div className="pricePart">
                <input className="billPrice" value={form?.price} onChange={(input) => setForm(prev => ({
                  ...prev,
                  price: Number(input.target.value)
                }))}/>
              </div>
            </span>
              <span className="billItems dashboardBill">
              <h3 className="billItem">تخفیف</h3>
              <div className="pricePart">
                <input className="billPrice" value={form?.discountAmount || 0} onChange={(input) => setForm(prev => ({
                  ...prev,
                  discountAmount: Number(input.target.value) > 0 ? Number(input.target.value) : 0
                }))}/>
              </div>
            </span>
              {/*   <span className="billItems dashboardBill"> */}
              {/*   <h3 className="billItem">مالیات</h3> */}
              {/*   <div className="pricePart"> */}
              {/*     <h1 className="billPrice">3%</h1> */}
              {/*   </div> */}
              {/* </span> */}
              <hr className="dashedBill"/>
              {/*   <span className="billItems dashboardBill"> */}
              {/*   <h3 className="billItem">مبلغ قابل پرداخت</h3> */}
              {/*   <div className="pricePart"> */}
              {/*     <input className="tablePrice1" value={form?.finalPrice} onChange={(input) => setForm(prev => ({ ...prev, finalPrice: Number(input.target.value)}))}/> */}
              {/*   </div> */}
              {/* </span> */}
            </div>
          </div>
        </section>
        <section className="bottom width100">
          <h6 className="dashBoardTitle">محصولات</h6>
          {/* <Select */}
          {/*   options={[{ id: null }, ...productReducer.products.filter(e => !e.parent)].map(e => ({ value: e.id, label: productReducer.products.find(j => j.id == e?.id)?.title || 'انتخاب کنید' }))} */}
          {/*   value={{value: form?.productId, label: productReducer.products.find(e => e.id == form?.productId)?.title || 'انتخاب کنید'}} */}
          {/*   onChange={(selected) => {setForm(prev => ({ ...prev, productId: selected.value }))}} */}
          {/*   className='width300p' */}
          {/* /> */}
          <table className="productTable">
            <thead className="editOrderTable">
            <th className="sideBarTitle center">قیمت کل</th>
            <th className="sideBarTitle center">قیمت واحد</th>
            <th className="sideBarTitle center">تعداد</th>
            <th className="sideBarTitle center">محصول</th>
            <th className="sideBarTitle center">ردیف</th>
            <th className="sideBarTitle center"></th>
            </thead>
            <tbody>
            {list()}
            <tr className="addProductTr">
              <td className="addProductButton clickable" onClick={() => setForm(prev => ({
                ...prev,
                orderProducts: [...prev.orderProducts, {
                  productId: 1,
                  count: 1
                }]
              }))}>اضافه کردن محصول
              </td>
            </tr>
            </tbody>
          </table>
        </section>
      </main>
      </body>
    </>
  );
};
export default OrderManage;