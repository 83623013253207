import moment from 'jalali-moment';
import { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../../layouts/Header';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';
import SideBar from './SideBar';

const OrderDetail = ({ order, setOrder }: any) => {
  const homeReducer = useAppSelector(state => state.homeReducer);
  const navigate = useNavigate();
  const status = homeReducer.statuses[order?.status];
  const euroPrice = useAppSelector(state => state.tickerReducer.euroPrice)
  const [statusCount, setStatusCount] = useState(10);

  const statusList = () => {
    const rows: ReactElement[] = [];

    order?.orderStatuses?.slice(0, statusCount)?.reverse()?.map((orderStatus, index) => {
      rows.push(
        <tr key={'status' + index}>
          <td color={orderStatus.orderStatus.color}>{orderStatus.orderStatus.title}</td>
          <td>{orderStatus.description}</td>
          <td>{moment(orderStatus.createdAt).format('jYYYY/jMM/jDD')}</td>
          {index == 1 && <td className="clickable" onClick={() => setStatusCount(statusCount > 1 ? 1 : order?.orderStatuses?.length)}>مشاهده همه</td>}
        </tr>
      )
    })

    return rows;
  };

  const list = () => {
    const rows: ReactElement[] = [];

    order?.products?.map((product: any, index) => {
      rows.push(
        <div className={`preOrderSectiom ${(index % 2 == 1) ? 'backgroundcolor' : ''}`} key={'product' + index}>
          <a className="orderAgain clickable" href={'/product/' + product?.product?.slug} target="_blank">سفارش دوباره</a>
          <span className="detailPriceContainer">
            <p className="firstPrice">{product?.discountPrice > 0 &&  <s>{tools.formatPrice(product?.price)}</s>} DHs{tools.formatPrice(product?.discountPrice || product?.price)}</p>
            {/* <p className="secondPrice">{tools.formatPrice(product?.priceToman)}</p> */}
          </span>
          <p className="orderQuantity">{product?.count}</p>
          <span className="productDtail">
            <p>{product?.product?.productGroup?.category?.title}</p>
          </span>
          <span className="nameContainer">
                  <p>{product?.product?.productGroup?.title}</p>
                  <p>{product?.product?.sku}</p>
                </span>
          <img src={tools.getMainPic(product?.product?.medias)?.url} className="width100p"/>
          <p className="preOrderNumber">{++index}</p>
        </div>
      )
    })

    return rows;
  };

  return(
    <div className="dashboardTableContainer">
      <div className="orderDtailHeader">
        <span className="orderDtailTitle">جزئیات سفارش</span>
        <span className="backContainer clickable" onClick={() => setOrder(undefined)}>
                <i className="back3svg"></i>
                <p>بازگشت</p>
              </span>
      </div>
      <section className="orderDtailTop">
        <table className="statusTable">
          <thead>
            <tr>
              <th>وضعیت سفارش</th>
              <th>توضیحات</th>
              <th>زمان</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {statusList()}
          </tbody>
        </table>
        <div className="factor">
                <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">مبلغ سفارش</h3>
              <div className="pricePart">
                <h1 className="billPrice">DHs {tools.formatPrice(order?.price)}</h1>
                {/* <h1 className="billPersianPrice">  {tools.formatPrice(order?.priceToman)} تومان  </h1> */}
              </div>
            </span>
          {order.payments?.length > 0 &&
              <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">نرخ حواله دبی مرحله اول</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.payments[0]?.havale)}</h1>
              </div>
            </span>
          }
          {order.payments?.length > 1 &&
              <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">نرخ حواله دبی مرحله دوم</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.payments[1]?.havale)}</h1>
              </div>
            </span>
          }
          <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">تخفیف(درهم)</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.discountPrice ? order?.price - order?.discountPrice : 0)}</h1>
              </div>
            </span>
          <hr className="dashedBill"/>

          <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">هزینه ارسال(تومان)</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.delivery?.priceToman || 0)}</h1>
              </div>
            </span>

          <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">هزینه اضافه(تومان)</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.extraPriceToman)}</h1>
              </div>
            </span>
          {/* <span className="OrderDtailbillItems"> */}
          {/*     <h3 className="orderDtailBillText">مالیات </h3> */}
          {/*     <div className="pricePart"> */}
          {/*       <h1 className="billPrice">%3</h1> */}
          {/*     </div> */}
          {/*   </span> */}
          <hr className="dashedBill"/>
          <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">مبلغ سفارش بدون هزینه</h3>
                <div className="tablePrice">
                <span >
                  DHs {tools.formatPrice((order?.discountPrice || order?.price) + ((order.delivery?.price) || 0))}
                </span>
              {/* <span className="tablePersianPrice"> */}
              {/*     <h5 className="tablePriceNumber">{tools.formatPrice((order?.discountPriceToman || order?.priceToman) + (order.delivery?.price || 0))} </h5> */}
              {/*     <h6 className="tablePriceTexe"> تومان</h6> */}
              {/*   </span> */}
            </div>
            </span>
          <br/>
          <span className="OrderDtailbillItems">
              <h3 className="orderDtailBillText">مبلغ قابل پرداخت(تومان)</h3>
                <div className="tablePrice">
                <span className="">
                  {tools.formatPrice((order?.discountPriceToman || order?.priceToman) + ((order.delivery?.priceToman) || 0) + (order.extraPriceToman || 0))}
                </span>
            </div>
            </span>
          <span className="orderBillButton">
                  سفارش دوباره
                </span>
        </div>
        <div className="orderDtailRight">
                <span className="orderDtailHeader">
                  <p>شماره سفارش</p>
                   <div className="backContainer clickable" onClick={() => navigate(`/profile/order/factor/${order.code}`)}>
                  <p className="orderDtailBillText">دانلود فاکتور</p>
                    <i className="dlSvg"></i>
                    </div>
                </span>
          <p className="productName">{order?.code}</p>
          <div className="infoContainer">
            <p className="orderDtailBillText">تاریخ سفارش</p>
            <p className="orderDtailBillText">تعداد محصول</p>
            <p className="orderDtailBillText">وضعیت</p>
          </div>
          <div className="infoContainer">
            <p>{moment(order?.createdAt).format('jYYYY/jMM/jDD')}</p>
            <p>{order?.products?.length}</p>
            <p className="catSendStatus" style={{color: status.color}}>{status?.title}</p>
          </div>
          <p className="adressTitle">آدرس</p>
          <p className="adressText">{order?.address?.text}</p>
        </div>
      </section>
      <section className="cardContainer">
        {list()}
      </section>
    </div>
  )
}
export default OrderDetail