  import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useTicker from '../../hooks/useTicker';
import Header from '../../layouts/Header';
  import { popupSlice } from '../../services/reducers';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';
  import AddAddress from '../UserDashboard/AddAddress';
import CartRow from './CartRow';
import { cart as cartRedux, } from '../../services/reducers/userSlice';

const Cart = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const userReducer = useAppSelector(state => state.userReducer);
  const { ticker } = useTicker();

  const send = async () => {
    if (!userReducer.address){
      Swal.fire({
        title: 'ناموفق',
        text: 'لطفا ابتدا آدرس اضافه کنید',
        icon: 'error',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          dispatch(popupSlice.middle(<AddAddress/>))
        }
      })
      return;
    }

    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/order/place', true).post({})

    if (res.code == 200){
      dispatch(cartRedux());
      navigate('/order/pay/success');
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }

    dispatch(setLoading(false))
  };

  const list = () => {
    const rows: ReactElement[] = [];
    userReducer.cart?.products?.map((product, index) => {
      rows.push(
        <CartRow index={++index} key={'productCart' + product.product.slug} product={product} />
    )});

    return rows;
  };

  return(
    <>
      <Header/>
      <section className="basketPage">
        <section className="basketBill">
          <div className="billTop">
            <h1 className="billTitle">صورتحساب</h1>
            <h4 className="billTitle1">با زدن دکمه پرداخت، مشخصات حساب نمایش داده میشود </h4>
            <span className="billItems">
              <h3 className="billItem">مبلغ سفارش</h3>
              <div className="pricePart">
                <h1 className="billPrice">DHs {tools.formatPrice(userReducer.cart?.price)}</h1>
                <h1 className="billPersianPrice">  {tools.formatPrice(ticker(userReducer.cart?.price))} تومان  </h1>
              </div>
            </span>
              <span className="billItems">
              <h3 className="billItem">تخفیف </h3>
              <div className="pricePart">
                <h1 className="billPrice">{ userReducer.cart?.discountPrice > 0 ? userReducer.cart?.price - userReducer.cart?.discountPrice : 0} DHs</h1>
              </div>
            </span>
            <span className="billItems">
              <h3 className="billItem">نوع سفارش</h3>
              <div className="pricePart">
                <h1 className="billPrice">{userReducer.cart?.isPre ? 'پیش سفارش' : 'موجود'}</h1>
              </div>
            </span>
            <hr className="dashedBill"/>
            <span className="billItems">
              <h3 className="billItem">مبلغ قابل پرداخت</h3>
              <div className="tablePrice">
                <span className="tablePrice">
                  DHs {tools.formatPrice(userReducer.cart?.discountPrice || userReducer.cart?.price)}
                </span>
              <span className="tablePersianPrice">
                  <h5 className="tablePriceNumber">{tools.formatPrice(ticker(userReducer.cart?.discountPrice || userReducer.cart?.price))} </h5>
                  <h6 className="tablePriceTexe"> تومان</h6>
                </span>
            </div>
            </span>
          </div>
          <div className="billBottom">
            <span className="billPay" onClick={send}>ثبت سفارش</span>
            {/* <a className="billLink">دانلود پیش فاکتور</a> */}
          </div>
        </section>
        <section className="basketContainer">
          <span className="basketTitle">سبد خرید</span>
          {list()}
        </section>
      </section>
    </>
  )
}
export default Cart