import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import useTicker from '../../hooks/useTicker';
import useUrlParam from '../../hooks/useUrlParam';
import Header from '../../layouts/Header';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
import { MenuRow } from './MenuRow';
import ProductModel from '../../layouts/ProductModel';

const BrandSingle = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [brand, setBrand] = useState<any>({ products: []});
  const [attributes, setAttributes] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [chosen, setChosen] = useState([]);
  const [onlyAvailable, setOnlyAvailable] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 24;
  const endOffset = itemOffset + itemsPerPage;
  let currentItems = (Object.values(brand?.products) as any)?.filter(e => onlyAvailable ? e?.status == 1 : true)?.sort((a, b) => b.discountPrice - a.discountPrice)?.sort((a, b) => a?.title?.localeCompare(b?.title))?.sort((a, b) => b.countForeign - a.countForeign)?.sort((a, b) => b.status - a.status)?.sort((a, b) => b.count - a.count)?.slice(itemOffset, endOffset)
  const pageCount = Math.ceil((Object.values(brand?.products) as any)?.filter(e => onlyAvailable ? e.status == 1 : true).length / itemsPerPage)
  const { calcPrice } = useTicker();
  const { setUrlParam, removeUrlParam } = useUrlParam();

  const attributeList = () => {
    const rows = [];
    attributes.filter(e => !e.isTechnology).map((attribute, index) => {
      rows.push(<MenuRow key={'attribute' + index} attribute={attribute}/>);
    });

    return rows;
  };

  const list = () => {
    const rows = [];

    currentItems?.map((model, index) => {
      rows.push(
        <ProductModel product={model.mainProduct} model={model} key={model.mainProduct.slug} />
      );
    });

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/tag/').get();
    // const res = await restApi(process.env.REACT_APP_BASE_URL + '/attribute/').get();

    if (res.code == 200) {
      setTags(res.data);
      // setAttributes(res.data);
    }

    dispatch(setLoading(false));
  };

  const fetchItem = async () => {
    dispatch(setLoading(true));
    let urlParams = '?'
    let index = 0
    for(let entry of searchParams.entries()) {
      ++index;
      if (entry[0] != 'page') {
        if (entry[0] == 'tags[]'){
          urlParams += ('tags[]=' + entry[1] + (index != (searchParams as any).size ? '&' : ''));
        }else{
          urlParams += ('attribute[]=' + entry[1] + (index != (searchParams as any).size ? '&' : ''));
        }
      }
    }
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/brand/' + slug + urlParams).get();

    if (res.code == 200) {
      setBrand(res.data);
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchItem();

    fetchData();
  }, []);

  useEffect(() => {
    fetchItem();
  }, [searchParams]);

  useEffect(() => {
    window.scroll(0,0);
  }, [itemOffset]);

  return (
    <>
      <Header/>
      <div className="brandBanner"></div>
      <main className="categoryPage">
        <section className="categoryLeft">
          <div className="cardHeader">
            <h1 className="categoryBreadCrumb2">{brand?.title}</h1>
          </div>
          <div className="categoryCardContainer">
            {list()}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="بعدی >"
            onPageChange={(event) => {
              setUrlParam('page', (event.selected + 1).toString());
              setItemOffset(((event.selected) * itemsPerPage));
            }}
            initialPage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
            forcePage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< قبلی"
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="paginationBreak"
            previousClassName="paginationBreak"
            nextClassName="paginationBreak"
            activeClassName="paginationActive"
          />
        </section>
        <aside>
          <ul className="catFilterContainer">
            <MenuRow chosen={chosen} attribute={{ title: 'تگ ها', attributeValues: tags}} setChosen={setChosen}/>

            {/* <li className="catLi"> */}
            {/*   <div className="catRow"> */}
            {/*     <p>فقط نمایش کالاهای موجود</p> */}
            {/*     <input type="checkbox" checked={onlyAvailable} onClick={() => { */}
            {/*       setSearchParams({['page']: '1'}) */}
            {/*       setOnlyAvailable(!onlyAvailable); */}
            {/*     }}/> */}
            {/*   </div> */}
            {/* </li> */}
            {/* {attributeList()} */}
          </ul>
        </aside>
      </main>
    </>
  );
};
export default BrandSingle;