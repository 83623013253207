import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useUrlParam from '../../hooks/useUrlParam';

export const MenuRow = ({ chosen, attribute, setChosen }: any) => {
  const [active, setActive] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const { setUrlParam, removeUrlParam } = useUrlParam();

  const list = () => {
    const rows = []
    attribute.attributeValues?.map((value, index) => {
      const checked = searchParam.getAll(`${attribute.slug}[]`).includes(value.slug);

      rows.push(
        <div className="sexShowMore" key={"value" + index}>
          <p>{value.title}</p>
          <i className={checked ? 'checkBoxChecked' : 'checkBox'} onClick={() => {
            removeUrlParam('page')
            if (checked) {
              removeUrlParam(`${attribute.slug}[]`, value.slug)
            } else {
              setUrlParam(`${attribute.slug}[]`, value.slug)
            }
          }}></i>
        </div>
      )
    })

    return rows
  };

  return (
    <li className="catLi">
      <div className="catRow">
        <p>{attribute.title}</p>
        <i className={!active ? 'plusIcon' : 'collapsIcon'} onClick={() => setActive(!active)}></i>
      </div>
      {active && list()}
    </li>
  );
};