import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import restApi from '../../../../services/restApi';
import { GuaranteeCard } from '../GuaranteeCard';

const GuaranteePrint = () => {
  const { id: paramId } = useParams();
  const [data, setData] = useState({});
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const fetchData = async () => {
    // dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/single/' + paramId, true).get()

    setData({
      id: res.data.id,
      brandTitle: res.data?.brand?.title,
      companyName: res.data?.user?.companyName || res.data?.user?.name,
      sku: res.data?.product?.sku,
      serial: res.data?.serial,
    })
    await setTimeout(() => { reactToPrintFn()}, 300)
    //
    // dispatch(setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <main className='guaranteeFactorContainer' ref={contentRef}>
      <GuaranteeCard data={data} />
    </main>
  );
};
export default GuaranteePrint;
