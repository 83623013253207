import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { popupSlice } from '../../services/reducers';
import { setLoading } from '../../services/reducers/homeSlice';
import { address } from '../../services/reducers/userSlice';
import restApi from '../../services/restApi';

const AddAddress = () => {
  const [states, setStates] = useState([]);
  const [state, setState] = useState<any>();
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState<any>();
  const [phone, setPhone] = useState('');
  const [postal, setPostal] = useState('');
  const [text, setText] = useState('');
  const dispatch: any = useDispatch();

  const send = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/address', true).post({
      province: state.id,
      city: city?.id,
      phone: phone,
      postal: postal,
      text: text,
    });

    if (res.code == 201) {
      dispatch(address());
      Swal.fire({
        title: 'موفق',
        text: 'ادرس شما با موفقیت ثبت شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          dispatch(popupSlice.hide());
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: 'مشکلی پیش آمده لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.',
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const fetchData = async () => {
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/address/states').get();

    setStates(res.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!state && states.length > 0) {
      setState(states[0]);
    }
    setCities(state?.cities);
    state?.cities[0];
    setCity(state?.cities[0]);
  }, [state]);

  return (
    <main className="addressModal">
      <div className="payDetail">
        <i className="modalExit"></i>
        <p>آدرس جدید</p>
      </div>
      <div className="addressContainer">
        <div className="addressMap">
          <img src="/img/mapScreen.png"/>
        </div>
        <div className="address">
          <span className="addressFromItem">
            <label htmlFor="city">شهرستان</label>
            <select id="city" className="addressInput dirRtl" onChange={(input) => setCity(cities.find(e => e.id == input.target.value))}>
              {cities?.map((e) => <option value={e.id} selected={e.id == city?.id} >{e.title}</option>)}
            </select>
          </span>
          <span className="addressFromItem">
            <label htmlFor="province">استان</label>
          <select id="province" className="addressInput dirRtl" onChange={(input) => setState(states.find(e => e.id == input.target.value))}>
            {states.map((e) => <option value={e.id} selected={e.id == state?.id} >{e.title}</option>)}
          </select>
          </span>
          <span className="addressFromItem">
            <label htmlFor="phone">شماره تلفن</label>
          <input id="phone" className="addressInput" onChange={(input) => setPhone(input.target.value)}/>
          </span>
          <span className="addressFromItem">
            <label htmlFor="postalCode">کد پستی</label>
          <input id="postalCode" className="addressInput" onChange={(input) => setPostal(input.target.value)}/>
          </span>
          <span className="addressFromItem">
            <label htmlFor="text">آدرس</label>
          <textarea className="addressText" id="text" onChange={(input) => setText(input.target.value)}/>
          </span>
        </div>
        <div className="addressButtons">
          <span className="notPay clickable" onClick={() => dispatch(popupSlice.hide())}>بازگشت</span>
          <span className="pay clickable" onClick={send}>ثبت</span>
        </div>
      </div>
    </main>
  );
};
export default AddAddress;