import { popupSlice } from "../../../services/reducers";
import { useDispatch } from "react-redux";

const Bill = ({ order }: any) =>{
  const dispatch: any = useDispatch();
  return(
    <main className="billMain flex">
      <div className="billSec">
        <span className="billHeader">
          تسویه
        </span>
        <span className="billDetSpan">
      <p>{order.discountPrice} </p>
      <p>مبلغ کل سفارش به درهم </p>
    </span>
        <span className="billDetSpan">
      <p>{order.payments[1]?.havale || '-'}</p>
      <p>نرخ درهم حواله دبی به تومان</p>
    </span>
        <span className="billDetSpan">
      <p>{order.delivery?.priceToman || '-'} </p>
      <p>هزینه ارسال</p>
    </span>
        <span className="billDetSpan">
      <p>{order.extraPriceToman || '-'} </p>
      <p>هزینه اضافه</p>
    </span>
        <span className="billDetSpan">
      <p>{order.payments[1]?.price || '-'}</p>
      <p>مبلغ پرداخت شده به درهم</p>
    </span>
    <span className="billFinalPrice">
      <p>مبلغ تسویه به تومان</p>
      <p>{(order.payments[1]?.price * order.payments[1]?.havale) + (order?.delivery?.priceToman || 0) + (order?.extraPriceToman || 0)|| '-'}</p>
    </span>
    <span className="billDetailSpan marginRight80">
     <span className="billDetailChild">
        <p>تاریخ واریز</p>
        <p>{order.payments[1]?.date || '-'}</p>
      </span>
     <span className="billDetailChild">
        <p>شماره پیگیری</p>
        <p>{order.payments[1]?.code || '-'}</p>
      </span>
        <span className="billDetailChild">
          <p>بانک مبدا</p>
          <p>{order.payments[1]?.bank || '-'}</p>
        </span>
    {/*    <span className="billDetailChild"> */}
    {/*   <p>فیش واریزی</p> */}
    {/*   <img src="/img/mellat%201.jpg"/> */}
    {/* </span> */}
      </span>
        <span className="dashboardHeader billClose clickable" onClick={() => dispatch(popupSlice.hide())}>
          بستن
        </span>
      </div>
      <div className="billSec borderDashedLeft">
         <span className="billHeader">
           پیش پرداخت
        </span>
        <span className="billDetSpan">
          <p>{order.discountPrice || order.price} </p>
          <p>مبلغ کل سفارش به درهم</p>
        </span>
        <span className="billDetSpan">
        <p>{order.payments[0]?.havale || '-'} </p>
      <p>نرخ درهم حواله دبی به تومان</p>
    </span>

        <span className="billDetSpan">
      <p>{order.payments[0]?.percent || '-'} </p>
      <p>درصد پیش پرداخت</p>
    </span>
        <span className="billFinalPrice">
      <p>مبلغ پیش پرداخت به تومان</p>
      <p>{(order.payments[0]?.price * order.payments[0]?.havale) || '-'}</p>
    </span>
        <span className="billDetailSpan marginRight80">
         <span className="billDetailChild">
      <p>تاریخ واریز</p>
      <p>{order.payments[0]?.date || '-'}</p>
    </span>
         <span className="billDetailChild">
      <p>شماره پیگیری</p>
      <p>{order.payments[0]?.code || '-'}</p>
    </span>
         <span className="billDetailChild">
      <p>بانک مبدا</p>
      <p>{order.payments[0]?.bank || '-'}</p>
    </span>
    {/*      <span className="billDetailChild"> */}
    {/*   <p>فیش واریزی</p> */}
    {/*   <img src="/img/mellat%201.jpg"/> */}
    {/* </span> */}
      </span>
      </div>
    </main>
  )
}
export default Bill