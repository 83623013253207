import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import menu from '../../pages/Dashboard/Menu';
import MenuModal from '../../pages/Home/MenuModal';
import SearchModal from '../../pages/Home/SearchModal';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
import { useAppSelector } from '../../services/store';

const Header = () => {
  const userReducer = useAppSelector(state => state.userReducer)
  const [result, setResult] = useState<any>();
  const [query, setQuery] = useState('');
  const [menus, setMenus] = useState([]);
  const [menu, setMenu] = useState<any>();
  const [activeImage, setActiveImage] = useState('');
  const navigate = useNavigate();

  const list = () => {
    const rows = [];

    menus.map((menu, index) => rows.push(
      <a key={'menu' + index} className="headerLinks" href={menu.link} onMouseEnter={() => setMenu(menu)}>
        {menu.title}
      </a>
    ));

    return rows;
  };

  const fetchSearch = async () => {
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/search/' + query).get();

    if (res.code == 200){
      setResult(res.data);
    }
  };

  const fetchData = async () => {
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/menu/').get();

    if (res.code == 200){
      setMenus(res.data);
    }
  };

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    query.length && fetchSearch()
  }, [query]);

  return (
    <header className="homeHeader">
      <div className="headerTopRow">
        <div className="headerTopLeft">
          <span className="headerIconContainer clickable" onClick={() => navigate('/profile')}>
            <i className="profileIcon"></i>
          </span>
          <span className="headerIconContainer clickable" onClick={() => navigate('/cart')}>
            <i className="cartIcon"></i>
          </span>
          <i className="seperatorLine"></i>
          <form className="seaechBox" onSubmit={(event) => {
            event.preventDefault();

            navigate('/search/' + query);
          }}>
            <i className="magnifierIcon"></i>
            <input className="searchInput" placeholder="جستجو" onChange={(input: any) => setQuery(input.target.value)}></input>
          </form>
        </div>
        <a href='/'>
          <img src="/img/logo.png" alt="brand"/>
        </a>
      </div>
      {query && result && <SearchModal result={result}/>}
      <div className="headerBottom">
        <a className="catalogLink" href="/catalog">کاتالوگ ها و ویدیو ها</a>
        {userReducer?.profile?.role == 'SUPER_ADMIN' && <a className="catalogLink" href={window.location.href.includes('product') ? '/dashboard/product/edit/' + window.location.href.split('/')[4] :'/dashboard/product'}>داشبورد</a>}
        <div className="headerBottomRight">
          {list()}
        </div>
      </div>
      {menu && menu.children.length > 0 &&  <MenuModal activeImage={activeImage} setActiveImage={setActiveImage} menu={menu} setMenu={setMenu}/> }
    </header>
  )
};
export default Header;
